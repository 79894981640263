<template>
  <va-data-table
      class="ekitable"
      :items="licenses"
      :columns="columns"
      clickable
      no-data-html="<div class='no-data'>Cargando pistas...</div>"
      :sticky-header="true"
      :loading="false"
      allow-create
      loading-text=""
  >
    <template #cell(validUntil)="{ rowData }">
      {{ rowData.validUntil?rowData.validUntil.substring(0,10):"Pago único" }}
    </template>
    <template #cell(UserLicenseHotmartWebhook.orderDate)="{ rowData }">
      {{ rowData.UserLicenseHotmartWebhook.orderDate.substring(0,10) }}
    </template>
    <template #cell(active)="{ rowData }">
      <span v-if="rowData.active">Activa</span>
    </template>
  </va-data-table>
</template>
<script>

import requester from "@/services/requester";

export default {
  name: 'CustomerDetail',

  data() {
    return {
      details:{},
      licenses:[],
      columns: [
        {key: 'UserLicenseHotmartWebhook.productName', sortable: false, label: 'Producto'},
        {key: 'UserLicenseHotmartWebhook.price', sortable: false, label: 'Precio'},
        {key: 'active', sortable: false, label: 'Estado'},
        {key: 'UserLicenseHotmartWebhook.orderDate', sortable: false, label: 'Fecha de compra'},
        {key: 'validUntil', sortable: false, label: 'Fecha de vencimiento'},
        {key: 'maxAllowedConnections', sortable: false, label: 'Dispositivos'},
        {key: 'access', sortable: false, label: 'Canciones'}

      ],
    }

  },
  methods: {
    async getDetail() {
      try {
        const data = await requester.Get("/get-customer/" + this.$route.params.id, true);
        if (data.status === 200) {
          this.details = data.data;
          this.licenses = this.details.userLicense;
          console.log(this.licenses);
          this.$store.dispatch("utils/setUpCrumbDetail", {crumbLabel: this.details.fullname});

        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getDetail();
  },
}
</script>