<template>
  <div class="home general" v-on:scroll="onScroll">
    <h1 class="section-title">
      Lista de Canciones
      <va-button
        color="info"
        @click="createSong()"
      >
        Crear Canción
      </va-button>
    </h1>

    <va-data-table
        class="ekitable"
        :items="songs"
        :columns="columns"
        clickable
        no-data-html="<div class='no-data'>Cargando pistas...</div>"
        :sticky-header="true"
        :loading="false"
        allow-create
        loading-text=""
    >
      <template #headerPrepend>
        <tr>
          <th colspan="6">
            <va-input class="search-bar" title="Buscar Canciones" v-title placeholder="¿Qué canción buscas?" v-on:keyup.enter="onEnter" v-on:focusout="onEnter" v-model="search">
              <template #prependInner>
                <font-awesome-icon :icon="['fa-solid', 'search']" />
              </template>
            </va-input>
          </th>
        </tr>
      </template>

      <template #header(num)="{ label }">
        <div class="label ekiflex flex-vcenter flex-hbetween" title="Ordenar por Número" v-title @click="sortList('num')">
          <div>
            {{ label }}
          </div>
          <font-awesome-icon :icon="['fa-solid', getIcon('num')]" size="1x"/>
        </div>
      </template>

      <template #header(title)="{ label }">
        <div class="label ekiflex flex-vcenter flex-hbetween" title="Ordenar por Título" v-title @click="sortList('title')">
          <div>
            {{ label }}
          </div>
          <font-awesome-icon :icon="['fa-solid', getIcon('title')]" size="1x"/>
        </div>
      </template>

      <template #header(gen)="{ label }">
        <div class="label ekiflex flex-vcenter flex-hbetween" title="Ordenar por Género" v-title @click="sortList('gen')">
          <div>{{ label }}</div>
          <font-awesome-icon :icon="['fa-solid', getIcon('gen')]" size="1x"/>
        </div>
      </template>

      <template #cell(image)="{ rowData, rowIndex }">
        <va-avatar class="border-square"
                  size="small"
                  :src="rowData.image"/>
      </template>

      <template #cell(title)="{ rowData }">
        <router-link v-if="rowData.id"
            title="Ir a Detalle de la Canción" v-title
            :to="{ name: 'songDetail', params: { id: rowData.id } }"
            class="song-title linked"
        >{{ rowData.title }}
        </router-link>
        <div v-else class="song-title">{{ rowData.title }}</div>
        <br/>
        <router-link
            v-if="rowData['art.id']"
            title="Ir a Detalle del Artista" v-title
            :to="{ name: 'artistDetail', params: { id: rowData['art.id']} }"
            class="table-span linked"
        >{{ rowData['art.name'] }}
        </router-link>
        <div class="table-span linked" v-else>{{ rowData['art.name'] }}</div>
      </template>

      <template #cell(gen)="{ rowData }">
        <router-link v-if="rowData['gen.id']"
            title="Ir a Detalle del Género" v-title
            :to="{ name: 'genreDetail', params: { id: rowData['gen.id'] } }"
            class="table-span linked"
        >{{ rowData['gen.name'] }}
        </router-link>
        <div class="table-span linked" v-else>{{ rowData['gen.name'] }}</div>
      </template>

      <template #cell(active)="{ row, isExpanded, rowIndex }">
        <div class="actions">
          <va-switch
            v-model="songs[rowIndex].active"
            @update:modelValue="saveSong(rowIndex)"
            title="Cambiar estado" 
            v-title color="success"
            class="action"
          />
          <va-button
            color="#ffffff"
            class="action"
            title="Editar Canción" 
            v-title
            @click="row.toggleRowDetails()"
            :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
            preset="secondary"
          >
            Editar
          </va-button>
        </div>        
      </template>

      <template #expandableRow="{ rowData }">
        <div class="edit-container">
          <div class="title">Edición</div>
          <div class="config">
            <va-input
              v-model="songs[getIndex(rowData.id ?? rowData.localId)].title"
              class="input"
              label="Título"
              title="Cambiar título" 
              v-title
              placeholder="Título"
            />
            <va-input
              v-model="songs[getIndex(rowData.id ?? rowData.localId)].description"
              class="input"
              type="textarea"
              label="Descripción"
              title="Cambiar descipción" 
              v-title
              placeholder="Descripción"
            />

            <va-input
            v-model="songs[getIndex(rowData.id ?? rowData.localId)].videoFile"
            class="input"
            label="ID Video Url"
            title="Cambiar ID Video Url"
            v-title
            placeholder="ID Video Url"
            />

            <iframe
              width="500"
              height="300" 
              :src="`https://www.youtube.com/embed/` + `${ songs[getIndex(rowData.id ?? rowData.localId)].videoFile }`"
              frameborder="0"
              style="margin: 5px 10px;"
              ref="frame"
              referrerpolicy="strict-origin-when-cross-origin">
            </iframe>


            <div class="w-100" style="width: 100%;">
              <va-input
                v-model="rowData.lyricsText"
                class="input"
                label="Letra"
                title="Cambiar letra" 
                v-title
                type="textarea"
                min-rows="10"
                placeholder="Letra" />
              <va-file-upload
                dropzone
                type="single"
                file-types=".lrc"
                v-model:model-value="newLrc"
                @file-added="checkLrcFile(getIndex(rowData.id ?? rowData.localId))"
                @file-removed="checkLrcFile(getIndex(rowData.id ?? rowData.localId))"
              >
              <div class="upload-area">
                <h1 class="title">{{ rowData.lyricsPath??'Cargar LRC' }}</h1>
                <div class="hover-info file">
                  Subir LRC
                </div>
              </div>
              </va-file-upload>
            </div>


          </div>
          <!-- Segundo Config -->
          <div class="config">
            <div class="field c-1-2">
              <span class="va-title">Foto</span>
              <va-file-upload
                dropzone
                type="single"
                file-types="image/*"
                v-model:model-value="newImg"
                @file-added="checkImgFile(getIndex(rowData.id ?? rowData.localId))"
                @file-removed="checkImgFile(getIndex(rowData.id ?? rowData.localId))"
                hideFileList
              >
              <div class="upload-area">
                <div class="hover-info">
                  Cambiar imagen
                </div>
                <img
                  :src="rowData.image"
                  width="100"
                  height="100"
                >
              </div>
              </va-file-upload>
            </div>            
            <div class="field c-1-2">
              <span class="va-title">Num</span>
              <va-counter
                :min="0"
                :max="20000"
                :step="1"
                v-model="songs[getIndex(rowData.id ?? rowData.localId)].num"
                manual-input
                class="input"
                title="Cambiar num"
                v-title
              /> 
              <span class="va-title">Rating</span>
              <va-counter
                :min="0"
                :max="1000"
                :step="1"
                v-model="songs[getIndex(rowData.id ?? rowData.localId)].rate"
                manual-input
                class="input"
                title="Cambiar rating" 
                v-title
              /> 
            </div>
            <div class="field c-1-2">
              <span class="va-title">Archivo de Pista</span>
              <va-file-upload
                dropzone
                type="single"
                file-types=".mp3"
                v-model:model-value="newSong"
                @file-added="checkSongFile(getIndex(rowData.id ?? rowData.localId))"
                @file-removed="checkSongFile(getIndex(rowData.id ?? rowData.localId))"
              >
              <div class="upload-area">
                <h1 class="title">{{ rowData.trackPath??'Cargar Pista' }}</h1>
                <div class="hover-info file">
                  Subir mp3
                </div>
              </div>
              </va-file-upload>
              
              <audio v-if="songs[getIndex(rowData.id ?? rowData.localId)].trackFileType==='file'" controls>
                <source :src="getSongUrl(songs[getIndex(rowData.id ?? rowData.localId)].num)" 
                 type="audio/mpeg">
              </audio>
              <audio controls v-else>
                <source  :src="songs[getIndex(rowData.id ?? rowData.localId)].trackFileURL" type="audio/mpeg">
              </audio>
              
                
            </div>
            
            <div class="field c-1-2">
              <va-select
                v-model="songs[getIndex(rowData.id ?? rowData.localId)].gen"
                label="Genero"
                placeholder="Buscar Género..."
                :options="genres"
                searchable clearable
                highlight-matched-text
                text-by="name"
                @update:modelValue="(val) => showNewGenre(val, getIndex(rowData.id ?? rowData.localId))"
                @update-search="(value) => getGenres(value)"
              >
                <template #content="{ value }">
                  <va-chip
                    v-for="chip in value"
                    :key="chip"
                    size="small"
                  >
                    {{ chip.name }}
                  </va-chip>
                </template>
              </va-select>
            </div> 
            <div class="field c-1-2">
              <va-select
                v-model="songs[getIndex(rowData.id ?? rowData.localId)].artistas"
                label="Artista"
                placeholder="Buscar Artista..."
                :options="artists"
                searchable clearable
                highlight-matched-text
                text-by="name"
                @update:modelValue="(val) => showNewArtist(val, getIndex(rowData.id ?? rowData.localId))"
                @update-search="(value) => getArtists(value)"
                multiple
              >
                <template #content="{ value }">
                  <va-chip
                    v-for="chip in value"
                    :key="chip"
                    size="small"
                  >
                    {{ chip.name }}
                  </va-chip>
                </template>
              </va-select>
            </div>


            <div class="field c-1-2">
              <span class="va-title">Archivo de voz guía</span>
              
              <va-file-upload
                dropzone
                type="single"
                file-types=".mp3"
                v-model:model-value="newVoice"
                @file-added="checkVoiceFile(getIndex(rowData.id ?? rowData.localId))"
                @file-removed="checkVoiceFile(getIndex(rowData.id ?? rowData.localId))"
               
              >
              <div class="upload-area">
                <h1 class="title">{{ rowData.trackPath??'Cargar voz guía' }}</h1>
                <div class="hover-info file">
                  Subir mp3 
                </div>
              </div>
              </va-file-upload>
              
              <audio v-if="songs[getIndex(rowData.id ?? rowData.localId)].voiceFileType==='file'" controls>
                <source :src="getVoiceUrl(songs[getIndex(rowData.id ?? rowData.localId)].num)" 
                 type="audio/mpeg">
              </audio>
              <audio controls v-else>
                <source  :src="songs[getIndex(rowData.id ?? rowData.localId)].voiceFileUrl" type="audio/mpeg">
              </audio>
              
                
            </div>



            <div class="field c-1-2">
              <span class="va-title">¿Activo?</span>
              <va-switch
                v-model="songs[getIndex(rowData.id ?? rowData.localId)].active"
                title="Cambiar estado" 
                v-title color="success"
                class="input"
              />
            </div>


            <div class="field c-1-2" style="width: 100%;" v-if="rowData.lyricsText">
              <textarea disabled="disabled" rows="20">{{ formatValue(rowData.lyricsText) }} </textarea>
            </div>

          </div>
          <!-- Botones -->
          <div style="padding: 0.5rem;">
            <va-button
            color="warning"
            :loading=isLoading
            class="btn"
            @click="saveSong(getIndex(rowData.id ?? rowData.localId))"
            >
            Guardar
            </va-button>
          </div>
          <div style="padding: 0.5rem;">
            <va-button
            color="danger"
            :loading=isLoading
            class="btn"
            v-if="rowData.id"
            @click="deleteSong(getIndex(rowData.id))"
            >
            Eliminar
            </va-button>
            </div>
        </div>
      </template>
    </va-data-table>
  </div>
</template>

<script>
import requester from "../../services/requester";
import { useToast } from "vue-toastification";

const toast = useToast();
import store from '@/store'
export default {
  
  data() {
    return {
      search: "",
      songs: [],
      genres: [],
      artists: [],
      columns: [
        {key: 'image', sortable: false, label: ' ', width: '35px'},
        {key: 'num', sortable: false, label: 'N°'},
        {key: 'title', sortable: false, label: 'Título'},
        {key: 'gen.name', sortable: false, label: 'Género'},
        {key: 'rate', sortable: true, label: 'Rating'},
        {key: 'active', sortable: false, label: 'Activo'},
      ],
      songsPage: 1,
      isLoading: false,
      sortings: new Map(),
      sortingOrder: null,
      sortBy: null,
      continueLoading: true,
      localId: 0,
      newImg: [],
      newSong: [],
      newLrc: [],
      musicDownload: "",
      token: null
    };
  },

  name: "SongsList",

  methods: {
    onEnter(){
      this.songsPage = 1;
      this.getSongsFromApi();
      this.tokens = store.getters['user/tokens'];
      console.log(this.tokens);
    },

    getIcon(sortBy){
      const sortingOrder = this.sortings.get(sortBy);
      return !sortingOrder?'sort':sortingOrder==='ASC'?'sort-up':'sort-down';
    },

    setSorting({sortBy, sortingOrder}){
      if(this.sortBy === sortBy && this.sortingOrder === sortingOrder) {
        return;
      }
      this.sortBy = sortBy;
      this.sortingOrder = sortingOrder;
      this.onEnter();
    },

    sortList(sortBy){
      const sortingOrder = this.sortings.get(sortBy) === 'ASC'?'DESC':'ASC';
      this.sortings.set(sortBy,sortingOrder)
      this.setSorting({sortBy, sortingOrder})
    },

    async getSongsFromApi(isScroll = false){
      this.isLoading = true;
      const data = await requester.Get(`/get-songs/${this.songsPage}/?title=${this.search}&sortBy=${this.sortBy}&sortingOrder=${this.sortingOrder}`, true);
      
      if (data.status === 200) {
        if(!isScroll) {
          this.songs = data.data.songs.map((el) => { 
            el.active = el.active == 1 ? true : false; 
            return {
              ...el,
           
              lyricsFile: "",
              trackFileContent: `${process.env.VUE_APP_PLAYER_URL}/play-song/ECK ${el.num}.mp3?auth=${this.tokens.token}`,
              voiceFileContent: `${process.env.VUE_APP_PLAYER_URL}/play-voice/${el.num}.mp3?auth=${this.tokens.token}`,
              trackFileType: "file",
              voiceFileType: "file",
              trackFile: "",
              lyricsFileContent: "",
              artistas:[
                el.art,
                ...el.feats
              ]
            
            }; 
          });

          console.log(this.songs);
          
        }else{
          this.songs = this.songs.concat(data.data.songs.map((el) => { 
            el.active = el.active == 1 ? true : false; 
            return {
              ...el,
             
              lyricsFile: "",
              trackFileContent: `${process.env.VUE_APP_PLAYER_URL}/play-song/ECK ${el.num}.mp3?auth=${this.tokens.token}`,
              voiceFileContent: `${process.env.VUE_APP_PLAYER_URL}/play-voice/${el.num}.mp3?auth=${this.tokens.token}`,
              trackFile: "",
              lyricsFileContent: "",
              trackFileType: "file",
              voiceFileType: "file",
              artistas:[
                el.art,
                ...el.feats
              ]
            }; 
          }));
        }
        if(data.data.songs.length>0) {
          this.songsPage++;
          this.continueLoading = true;
        }
        else{
          this.continueLoading = false;
        }
      }
      this.isLoading = false;
    },

    onScroll(event) {
      if(!this.continueLoading){
        return;
      }
      if (!this.isLoading) {
        const {target} = event;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
          this.getSongsFromApi(true);
        }
      }
    },

    setSorting({sortBy, sortingOrder}){
      if(this.sortBy === sortBy && this.sortingOrder === sortingOrder) {
        return;
      }
      this.sortBy = sortBy;
      this.sortingOrder = sortingOrder;
      this.onEnter();
    },

    createSong(){
      const data = {
        localId: "localId"+this.localId,
        title: "Nueva Canción",
        "art.id": null,
        "art.name": "Artista",
        "gen.id": null,
        "gen.name": "Género",
        rate: 1,
        active: false,
        description: '',
        image: null,
       
        lyricsFile: "",
        trackFileContent: "",
        trackFileType: "file",
        voiceFileType: "file",
        trackFile: "",
        lyricsFileContent: "",
        videoFile: "",
        artistas:[]
      }
      this.songs.splice(0, 0, data);
      this.localId++;
    },

    async saveSong(index){
      if(index >= 0 && index < this.songs.length){
        this.isLoading = true;
        const res = await requester.Put('/save-song', true, {...this.songs[index],trackFileType:"",trackFileURL:"", voiceFileType: ""}, { noEncrypt: true });
        console.log(res.feats);
        if(res.status == 200){
          this.songsPage = 1;
          this.getSongsFromApi();
          this.isLoading = false;
          toast.success('Guardado exitosamente!');
        }
      }                  
    },

    async deleteSong(index){
      if(index >= 0 && index < this.songs.length){
        const res = await requester.Delete('/delete-song/'+this.songs[index].id, true);
        if(res.status == 200){
          this.songs.splice(index, 1);
          toast.success("Borrado exitosamente");
        }
      }                  
    },

    checkImgFile(index){
      let reader = new FileReader();
      reader.readAsDataURL(this.newImg)
      const me = this;
      reader.onload = function() {
        me.songs[index].image = reader.result;
      };
    },

    checkSongFile(index){
      if(!this.newSong){
        this.songs[index].trackFileType = 'file';
        this.songs[index].trackFileURL = '';
        this.songs[index].trackFileContent = '';
        return;
      }

      const reader = new FileReader();
      const me = this;
      reader.onload = function() {
        me.songs[index].trackFileType = 'base64';
        me.songs[index].trackFileURL = URL.createObjectURL(me.newSong);
      };
      reader.readAsArrayBuffer(this.newSong);

      //read audio as base64
      const reader2 = new FileReader();
      reader2.onload = function() {
        me.songs[index].trackFileContent = reader2.result;
      };
      reader2.readAsDataURL(this.newSong)

      
    },

    checkVoiceFile(index){
      if(!this.newVoice){
        this.songs[index].voiceFileType = 'file';
        this.songs[index].voiceFileUrl = '';
        this.songs[index].voiceFileContent = '';
        this.songs[index].hasVoice = false;
        return;
      }
      this.songs[index].hasVoice = true;
      const reader = new FileReader();
      const me = this;
      reader.onload = function() {
        me.songs[index].voiceFileType = 'base64';
        me.songs[index].voiceFileUrl = URL.createObjectURL(me.newVoice);
      };
      console.log(this.newVoice);
      reader.readAsArrayBuffer(this.newVoice);
      //read audio as base64
      const reader2 = new FileReader();
      reader2.onload = function() {
        me.songs[index].voiceFileContent = reader2.result;
      };
      reader2.readAsDataURL(this.newVoice)

      
    },

    checkLrcFile(index){
      if(!this.newLrc){
        //file removed
        this.songs[index].lyricsText = "";
        return;
      }
      let reader = new FileReader();
      const me = this;
      console.log(this.newLrc)
      reader.onload = function() {
        me.songs[index].lyricsText = reader.result; 
      };
      reader.readAsText(this.newLrc, 'latin1');
    },

    getIndex(id){
      if(!id.includes("localId")){
        return this.songs.findIndex((el) => el.id == id);
      }else{
        return this.songs.findIndex((el) => el.localId == id);
      }
    },

    async getGenres(name = ""){
      const data = await requester.Get(`/get-generos/1/?name=${name}&sortBy=null&sortingOrder=null`, true);
      if(data.status === 200){
        this.genres = data.data.generos;
      }
    },

    async getArtists(name = ""){
      const data = await requester.Get(`/get-artistas/1/?name=${name}&sortBy=null&sortingOrder=null`, true);
      if(data.status === 200){
        this.artists = data.data.artistas;
      }
    },

    showNewArtist(art, index){
      /*console.log(artId);
      
      const newArtist = this.artists.find((el) => el.id == artId);
      console.log(newArtist);*/
      console.log(index);
      this.songs[index]['art.id'] = art.id;
      this.songs[index]['art.name'] = art.name;
    },

    showNewGenre(genId, index){
      const newGenre = this.genres.find((el) => el.id == genId);
      this.songs[index]['gen.id'] = genId;
      this.songs[index]['gen.name'] = newGenre.name;
    },

    async downloadLRC(index) {
      this.isLoading = true;

      let lyricsPath = {
        fileName: index
      }

      const data = await requester.Post(`/get-file`,true, lyricsPath);
      if (data.status === 200) {
        this.downloadBase64File('application/octet-stream', data.data.content, index);
        this.isLoading = false;
      }
    },

    downloadBase64File(contentType, base64Data, fileName) {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    formatValue(text){
      let lyricsText = text
      lyricsText = lyricsText.replace(/(<|\[)+(\d*:\d*.\d*)+(>|\])/g, "");//remove the time tags
      lyricsText = lyricsText.replace(/\r?\n|\r/g,' '); //remove the breaklines

      return lyricsText
    },
    getSongUrl(num){
      //me.songs[index].trackFileContent
      return  `${process.env.VUE_APP_PLAYER_URL}/play-song/ECK ${num}.mp3?auth=${this.tokens.token}`;
    } ,
    getVoiceUrl(num){
      //me.songs[index].trackFileContent
      return  `${process.env.VUE_APP_PLAYER_URL}/play-voice/${num}.mp3?auth=${this.tokens.token}`;
    } ,


  },

  created() {
    this.search = this.$route.query.q ?? "";
    this.onEnter();
    this.getArtists();
    this.getGenres();
  },
};
</script>

<style lang="scss" src="./songs.scss" scoped></style>
